'use strict';
const $ = require('jquery');
window.$ = $;
window.jQuery = $;

import '../css/styles.scss';
require('bootstrap');

import adminMenu from '@elasticms/admin-menu';
import back2top from "./back2top";
import ajaxSearch from "./ajax-search";
import toc from "./toc";
import externalLink from "./external-link";
import HashFile from "./HashFile";
import common from "./common";
import app from "./app";


adminMenu();
back2top();
ajaxSearch();
toc();
externalLink();
common();
app();
window.HashFile = HashFile;


console.log('gba website loaded!');