/**
 * app JS
 */
export default function app() {
    // ANIMATIONS
    var windowWidth = $(window).innerWidth();

    $('#privacy-details .multi-collapse').each(function() {
        var $children = $(this).children();

        for(var i = 0; i < $children.length; i++) {
            var $currentChild = $($children.get(i));
            if ($currentChild.is('h4')) {
                var collapseID = "collapse-id-"+i;
                // add collapse link
                var $collapseLink = $('<a class="collapse-list d-flex align-items-start" data-toggle="collapse" href="#'+collapseID+'" role="button" aria-expanded="false" aria-controls="'+collapseID+'">'+ $currentChild.text() +'<span class="ml-auto pl-3">'+ lessInfo.replace(' ', '&nbsp;') +'</span><span class="ml-auto pl-3">'+ moreInfo.replace(' ', '&nbsp;') +'</span></a>')
                // start collapse body
                var $collapseBody = $('<div class="collapse" id="'+ collapseID +'"></div>')
                var $collapseContent = $('<div class="collapse-content"></div>');
                for (var j = i + 1; j < $children.length; j++) {
                    var $nextChild = $($children.get(j));
                    if ($nextChild.is('h4') || $nextChild.is('h3')) {
                        i = j-1;
                        break;
                    }

                    $collapseContent.append($nextChild);
                }

                $currentChild.replaceWith($collapseLink);
                $collapseBody.append($collapseContent);
                $collapseBody.insertAfter($collapseLink);
            }
        }
    });

    $(document).ready(function() {
        const body = document.querySelector('body');
        // let translations = JSON.parse(body.getAttribute('data-translations'));

        // $('a[target^="_blank"]:not(.nav-link):not(.btn)').each(function() {
        //     $(this).append('<span class="sr-only">'+translations.external_link+'</span>');
        // });

        $('.animated:not(.animated--simple)').each(function(i, elem) {
            var $elem = $(elem);
            $elem.waypoint(function(direction) {
                if (direction == 'up' || $elem.hasClass('in')) {
                    return;
                }

                $elem.addClass('in');
                var transition = 'transition.slideDownIn';
                
                if ($elem.data('from') == 'left') {
                    transition = 'transition.slideLeftIn';
                } else if ($elem.data('from') == 'right') {
                    transition = 'transition.slideRightIn';
                } else if ($elem.data('from') == 'down') {
                    transition = 'transition.slideUpIn';
                }

                var delay = 200;
                if (windowWidth > 992 && $elem.data('delay')) {
                    delay = $elem.data('delay');
                }

                $elem.velocity(transition, {
                    delay: delay,
                    complete: function() {
                        $elem.removeClass('animated');
                    }
                });
            }, {
                offset: '90%'
            });
        });

        $('.animated--simple').each(function(i, elem) {
            var $elem = $(elem);
            $elem.waypoint(function(direction) {
                if (direction == 'up' || $elem.hasClass('in')) {
                    return;
                }
                $elem.addClass('in');
                $elem.removeClass('animated');
            }, {
                offset: '95%'
            });
        });

        // auto open FAQ collapses
        if (window.location.hash) {
            // disable browser's scroll restoration because it conflicts with auto scroll to focused element
            if ('scrollRestoration' in history) {
                history.scrollRestoration = 'manual';
            }

            var $faqToggle = $('.accordion ' + window.location.hash + ' [data-toggle="collapse"]');
            var $faq = $($faqToggle.data('target'));
            if ($faq.length) {
                $faq.collapse('show');
                $('html, body').animate({
                    scrollTop: $faqToggle.offset().top - 50
                }, 200);
            }
        }

        $(document).on('click', '.collapse-all', function() {
            $('.multi-collapse').collapse('hide');
            $(this).hide();
            $('.expand-all').show();
            
            return false;
        });

        $(document).on('click', '.expand-all', function() {
            $('.multi-collapse').collapse('show');
            $(this).hide();
            $('.collapse-all').show();
            
            return false;
        });
    });
}