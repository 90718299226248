/**
 * app JS
 */
export default function common() {
    //$('.subgroup-select').on('click', function(e) {
    $(document).on('click', '.subgroup-select', function(e) {
        e.preventDefault();
        var subgroupClass = $(this).data('subgroup-class');
        subgroupClass = subgroupClass.split(' ');
        subgroupClass = subgroupClass[0];

        var $inputs = $('input:not(:disabled).' + subgroupClass);

        $inputs.prop('checked', $('input.' + subgroupClass + ':checked').length < $inputs.length);
        $($inputs[0]).trigger('change');
    });

    // ANIMATIONS
    var windowWidth = $(window).innerWidth();

    $(window).on('resize', function() {
        windowWidth = $(window).innerWidth();

        if (windowWidth < 992) {
            var $headerSticky = $('header.sticky');
            if ($headerSticky.length) {
                $headerSticky.remove();
            }
        }
    });

    $(document).ready(function() {
        if (windowWidth >= 768 && $('body.home-page').length == 0) {
            var $backtotop = $('.backtotop');
            if ($backtotop.length) {
                $(window).on('scroll', function(e) {
                    var currentScrollTop = $(window).scrollTop();
                    if (currentScrollTop >= 250 && !$backtotop.is(':visible')) {
                        $backtotop.fadeIn();
                    } else if (currentScrollTop < 250) {
                        $backtotop.fadeOut();
                    }
                });
            }
        }

        //Sticky headband ACC / TEST
        var url_acc = ["acc.ext", "acc.pub"];
        var url_test = ["test.paas"];

        for (var i = 0; i < url_acc.length; i++) {
            if(window.location.hostname.indexOf(url_acc[i]) > -1){
                $('body').addClass('accept');
            }
        }
        for (var i = 0; i < url_test.length; i++) {
            if (window.location.hostname.indexOf(url_test[i]) > -1){
                $('body').addClass('test');
            }
        }

        // radians_to_degrees
        function radians_to_degrees(radians) {
            var pi = Math.PI;
            return radians * (180/pi);
        }

        $('.skew').each(function() {
            var positive = this.hasAttribute('data-skew-pos');
            var angle = radians_to_degrees(Math.asin($(this).outerWidth() / Math.hypot($(this).height(), $(this).outerWidth())));
            var colorStart = $(this).data('color-start');
            var colorEnd = $(this).data('color-end');
            
            $(this).css('background', 'linear-gradient('+ (positive ? -90 - angle : -90 + angle) +'deg, '+ colorEnd +' 49%, '+ colorStart +' 51%)');
        });

        $('.table').each(function() {
            if ($(this).parent().hasClass('table-responsive')) return;
            $(this).wrap('<div class="table-responsive"></div>');
        });

        $('table:not([class*="table"])').each(function() {
            $(this).addClass('table table-bordered');
            $(this).wrap('<div class="table-responsive"></div>');
        });
    });
}